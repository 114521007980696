<template>
    <section id="services">
        <div class="container">
            <div class="row">

                <div class="header px-3 col-lg-6 text-center col-lg-12">
                    <h5 class="mb-2">
                        SERVICES
                    </h5>
                    <h1>
                        We help our clients leave a great digital impression
                    </h1>
                    <p class="my-3">
                        Blending creativity, knowledge and experience.                    
                    </p>
                </div>

                <div class="wrapper col-lg-6">
                    <div class="item d-flex">
                        <img class="d-block" src="@/plugins/visivig/reusables/reusables-home/services/assets/services-project-analysis-icon.svg" alt="logo">
                        <div class="content">
                            <h3>
                                Project Analysis
                            </h3>
                            <ul>
                                <li>
                                    Requirements gathering
                                </li>
                                <li>
                                    Consultations
                                </li>
                                <li>
                                    Solution design
                                </li>
                                <li>
                                    Project documentation
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="wrapper col-lg-6">
                    <div class="item d-flex">
                        <img class="d-block" src="@/plugins/visivig/reusables/reusables-home/services/assets/services-design-icon.svg" alt="logo">
                        <div class="content">
                            <h3>
                                Design
                            </h3>
                            <ul>
                                <li>
                                    Wireframes
                                </li>
                                <li>
                                    UX/UI design
                                </li>
                                <li>
                                    Prototype
                                </li>
                                <li>
                                    Design manual
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="wrapper col-lg-6">
                    <div class="item d-flex">
                        <img class="d-block" src="@/plugins/visivig/reusables/reusables-home/services/assets/services-software-development-icon.svg" alt="logo">
                        <div class="content">
                            <h3>
                                Software Development
                            </h3>
                            <ul>
                                <li>
                                    Mobile apps
                                </li>
                                <li>
                                    Web apps
                                </li>
                                <li>
                                    Custom integrations
                                </li>
                                <li>
                                    Infrastructure
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="wrapper col-lg-6">
                    <div class="item d-flex">
                        <img class="d-block" src="@/plugins/visivig/reusables/reusables-home/services/assets/services-services-icon.svg" alt="logo">
                        <div class="content">
                            <h3>
                                Services
                            </h3>
                            <ul>
                                <li>
                                    Support and mantenance
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <button>
                WORK WITH US
            </button>

        </div>
    </section>
</template>


