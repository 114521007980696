<template>
    <section id="finax-second">
        <div class="container">
            <div class="row">

                <div class="col-lg-6 co-founder">
                    <div>
                        <div class="img-wrap align-items-center d-flex justify-content-center">
                            <img class="mb-auto" src="@/plugins/visivig/reusables/reusables-home/finax-second/assets/finax-man.webp" alt="co-founder">
                        </div>
                        <p class="content">
                            WEZEO’s development efforts elicited positive feedback from clients. The team professionally handled the entire product without disrupting normal business processes. They were consistent in their communication and proactive in their work ethic to reduce bugs or problems in the final product.
                        </p>
                        <p class="name">
                            JURAJ HRBATÝ / CO-FOUNDER & CEO AT 
                            <a href="#">FINAX</a>
                        </p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="img-wrap align-items-center d-flex justify-content-center">
                        <img class="mb-auto" src="@/plugins/visivig/reusables/reusables-home/finax-second/assets/finax-blue.svg" alt="finax-logo">
                    </div>
                    <p class="content">
                        Finax brings a revolution in investing to the CEE market. As the first we are making intelligent investing accessible to masses. We want to offer the same conditions and benefits to everyone. We want to change the perception of European on investing.
                    </p>
                    <a class="awards" href="#">
                        <img src="@/plugins/visivig/reusables/reusables-home/finax-second/assets/awards.svg" alt="awards">
                    </a>
                </div>

            </div>
        </div>
    </section>
</template>

