<template>
    <div class="entry-content">
        <R-intro-main />
        <R-awards />
        <R-finax-intro />
        <R-finax-second />
        <R-about-us />
        <R-services />
        <R-team />    
    </div>
</template>


<script>
import RIntroMain from '@/plugins/visivig/reusables/reusables-home/intro-main/r-intro-main.vue'
import RAwards from '@/plugins/visivig/reusables/reusables-home/awards/r-awards.vue'
import RFinaxIntro from '@/plugins/visivig/reusables/reusables-home/finax-intro/r-finax-intro.vue'
import RFinaxSecond from '@/plugins/visivig/reusables/reusables-home/finax-second/r-finax-second.vue'
import RAboutUs from '@/plugins/visivig/reusables/reusables-home/about-us/r-about-us.vue'
import RServices from '@/plugins/visivig/reusables/reusables-home/services/r-services.vue'
import RTeam from '@/plugins/visivig/reusables/reusables-home/team/r-team.vue'

export default {
    components: {
        RIntroMain,
        RAwards,
        RFinaxIntro,
        RFinaxSecond,
        RAboutUs,
        RServices,
        RTeam
    }
}
</script>