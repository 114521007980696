<template>
    <section id="finax-intro">
        <div class="container">
            <div class="row finax-intro align-items-center">

                <div class="col-lg-7 img-wrapper order-lg-2 position-relative">
                    <div class="logo d-lg-none">
                        <img src="@/plugins/visivig/reusables/reusables-home/finax-intro/assets/finax-logo.svg" alt="finax-logo">
                    </div>
                    <div class="finax-pc">
                        <img src="@/plugins/visivig/reusables/reusables-home/finax-intro/assets/pc-finax.webp" alt="pc-finax">
                        <div class="second">
                            <img src="@/plugins/visivig/reusables/reusables-home/finax-intro/assets/fx-video-content.svg" alt="finax-logo-blue">
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 finax-content order-lg-1">
                    <div class="logo d-none d-lg-block">
                        <img src="@/plugins/visivig/reusables/reusables-home/finax-intro/assets/finax-logo.svg" alt="finax-logo">
                    </div>
                    <h2>
                        The first online smart investment consulting platform developed with leading-edge technologies
                    </h2>
                    <p>
                        Finax is the first company that makes smart investments available to everyone. We have have been working on the product since the very beginning and are proud to have based the product on our technologies. The algorithm we have programmed evaluates the best possible investment plan individually for everyone and at the same time simplifies the access to the platform thanks to advanced facial biometrics technology.
                    </p>
                    <div class="app-stores d-flex flex-lg-wrap">
                        <a class="d-lg-block" href="#">
                            <img src="@/plugins/visivig/reusables/reusables-home/finax-intro/assets/appstore.svg" alt="app-store">
                        </a>
                        <a class="col-lg-12 col-xl d-lg-block mt-lg-3 mt-xl-0" href="#">
                            <img src="@/plugins/visivig/reusables/reusables-home/finax-intro/assets/googleplay.svg" alt="google-play">
                        </a>
                    </div>
                    <a class="visit" href="#">
                        <button class="btn">
                            VISIT WEBSITE
                        </button>
                    </a>
                </div>

            </div>
        </div>
    </section>
</template>


