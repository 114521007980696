<template>
    <section id="fumbi-next-case-study">
        <div class="container">
            <div class="row">

                <div class="col-12 title d-md-flex justify-content-between align-items-end mb-3">
                    <h2 class="fw-bold mb-4 mb-md-0">
                        Don’t miss next case study
                    </h2>
                    <a href="/case-study">
                        <div class="d-flex">
                            <p class="me-2">
                                See all cases studies
                            </p>
                            <picture>
                                <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-next-case-study/assets/next-case-study-arrow.svg" alt="arrow-right">
                            </picture>
                        </div>
                    </a>
                </div>

                <div class="col-12 col-lg-6 p-2 mb-3">
                    <div class="cloudtalk position-relative">
                        <img class="img-1" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-next-case-study/assets/case-study-cloudtalk-logo.svg" alt="cloudtalk-logo">
                        <img class="img-2" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-next-case-study/assets/case-study-cloudtalk-img.webp" alt="cloudtalk">
                        <a href="#"></a>
                    </div>
                </div>

                <div class="col-12 col-lg-6 p-2">
                    <div class="finax position-relative">
                        <img class="img-1" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-next-case-study/assets/case-study-finax-logo.svg" alt="cloudtalk-logo">
                        <img class="img-2" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-next-case-study/assets/case-study-finax-img.svg" alt="cloudtalk">
                        <a href="#"></a>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>


