<template>
    <section id="intro-main" class="position-relative text-center text-md-start overflow-hidden">
      
        <video id="introVideo" class="d-none d-md-block" autoplay muted loop width="300px" src="@/plugins/visivig/reusables/reusables-home/intro-main/assets/Wezeo.mp4"></video>

        <div class="container">
            <div class="box">
                <h1>
                    Wezeo is a software studio that helps companies develop digital products.
                </h1>
                <p class="mt-4">
                    We can take your idea to a market-ready MVP in just a few weeks!
                </p>
                <button type="button" class="btn">
                    I want to cooperate
                </button>
            </div>
        </div>

        <div class="container wrap-clients">
            <div class="clients d-flex flex-wrap text-center align-items-center">
                <div class="title col-12 col-lg mb-4 my-lg-3 px-lg-2">
                    WE’VE BUILT SOLUTIONS FOR…
                </div>
                <div class="col-6 col-lg my-3 px-2">
                    <a href="#">
                        <img src="@/plugins/visivig/reusables/reusables-home/intro-main/assets/finax-logo.svg" alt="finax">
                    </a>
                </div>
                <div class="col-6 col-lg my-3 px-2">
                    <a href="#">
                        <img src="@/plugins/visivig/reusables/reusables-home/intro-main/assets/vacatuner-logo.svg" alt="vacatuner">
                    </a>
                </div>
                <div class="col-6 col-lg my-3 px-2">
                    <a href="#">
                        <img src="@/plugins/visivig/reusables/reusables-home/intro-main/assets/retail.svg" alt="retail-solutions">
                    </a>
                </div>
                <div class="col-6 col-lg my-3 px-2">
                    <a href="#">
                        <img src="@/plugins/visivig/reusables/reusables-home/intro-main/assets/cloudtalk.svg" alt="cloudtalk">
                    </a>
                </div>
            </div>
        </div>

    </section>
</template>


