import { createRouter, createWebHistory } from "vue-router";

import PageHome from '@/plugins/visivig/pages/page-home.vue'
import PageFumbi from '@/plugins/visivig/pages/page-fumbi.vue'
import PageCaseStudy from '@/plugins/visivig/pages/page-case-study.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: PageHome
    },
    {
        path: '/case-study',
        name: 'Case-Study',
        component: PageCaseStudy
    },
    {
        path: '/case-study/fumbi',
        name: 'Fumbi',
        component: PageFumbi
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes  
})

export default router