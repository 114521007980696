<template>
  <div class="wrapper-content">
    <layout-header/>
    <router-view/>
    <layout-footer/>
  </div>
</template>

<script>
import LayoutHeader from '@/plugins/visivig/layout/layout-header.vue'
import LayoutFooter from '@/plugins/visivig/layout/layout-footer.vue'

export default {
  components: {
    LayoutHeader,
    LayoutFooter
  }
}
</script>
