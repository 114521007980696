<template>
    <section id="case-studies">
        <div class="container">
            <div class="row">

                <div class="col-12 title">
                    <h1 class="mb-3">
                        Case studies
                        <br>
                        that shape the market up
                    </h1>
                    <p>
                        Discover all the case studies which was made for our clients.
                    </p>
                </div>

                <div class="col-12 cs-finax">
                    <div class="inner d-flex position-relative">
                        <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finax-logo.svg" alt="finax-logo">
                        <div class="col-6 col-lg-3 d-flex align-items-end">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finax-phone1.webp" alt="finax-phone1">
                        </div>
                        <div class="col-6 col-lg-3 d-flex align-items-start">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finax-phone2.webp" alt="finax-phone2">
                        </div>
                        <div class="col-lg-3 d-none d-lg-flex align-items-end">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finax-phone3.webp" alt="finax-phone3">
                        </div>
                        <div class="col-lg-3 d-none d-lg-flex align-items-start">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finax-phone4.webp" alt="finax-phone4">
                        </div>
                        <div class="project-description">
                            <h3>
                                Finax
                            </h3>
                            <p>
                                Brand, UX/UI, Development
                            </p>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-wrap">
                    <div class="col-12 col-lg-6 cs-finax-second order-lg-1">
                        <div class="col-12 co-founder h-100 p-4">
                            <div class="img-wrap align-items-center d-flex justify-content-center mb-5 mb-lg-0">
                                <img class="mb-auto" src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finax-description-img.webp" alt="co-founder">
                            </div>
                            <p class="content">
                                WEZEO’s development efforts elicited positive feedback from clients. The team professionally handled the entire product without disrupting normal business processes. They were consistent in their communication and proactive in their work ethic to reduce bugs or problems in the final product.                            </p>
                            <p class="name">
                                JURAJ HRBATÝ / CO-FOUNDER & CEO AT 
                                <a href="#">FINAX</a>
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 cs-fumbi position-relative">
                        <a href="/case-study/fumbi">
                        </a>
                        <div class="inner d-flex position-relative">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-fumbi-logo.svg" alt="fumbi-logo">
                            <div class="col-12 img-main d-flex align-items-end justify-content-center">
                                <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-fumbi-img2.webp" alt="fumbi-phone">
                            </div>
                            <div class="project-description">
                                <h3>
                                    Fumbi
                                </h3>
                                <p>
                                    Brand, UX/UI, Development  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-wrap">
                    <div class="col-12 col-lg-6 cs-cloudtalk">
                        <div class="inner d-flex position-relative">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-cloudtalk-logo.svg" alt="cloudtalk-logo">
                            <div class="col-12 img-main d-flex align-items-end justify-content-center">
                                <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-cloudtalk-img2.webp" alt="cloudtalk">
                            </div>
                            <div class="project-description">
                                <h3>
                                    Cloudtalk
                                </h3>
                                <p>
                                    Development  
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 cs-finbot">
                        <div class="inner d-flex position-relative">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finbot-logo.svg" alt="finbot-logo">
                            <div class="col-12 img-finbot d-flex align-items-end justify-content-center">
                                <img class="position-absolute" src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-finbot-img.webp" alt="finbot">
                            </div>
                            <div class="wrapper-comming-soon">
                                <div class="comming-soon">
                                    <div class="position-relative">
                                        <h2>
                                            Comming soon...
                                        </h2>
                                    </div>   
                                 </div>
                            </div>
                            <div class="project-description">
                                <h3>
                                    FinBot
                                </h3>
                                <p>
                                    Brand, UX/UI, Development  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 cs-vacatuner">
                    <div class="inner d-flex position-relative">
                        <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-vacatuner-logo.svg" alt="vacatuner-logo">
                        <div class="col-lg-4 icon-vacatuner d-none d-lg-flex align-items-center justify-content-center">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-vacatuner-icon.webp" alt="vacatuner-icon">
                        </div>
                        <div class="col-12 col-lg-4 img-main d-flex align-items-end justify-content-center">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-vacatuner-phone1.webp" alt="vacatuner-phone1">
                        </div>
                        <div class="col-lg-4 img-main d-none d-lg-flex align-items-end">
                            <img src="@\plugins\visivig\reusables\resuables-case-study\case-studies\assets\case-study-vacatuner-phone2.webp" alt="vacatuner-phone2">
                        </div>

                        <div class="project-description">
                            <h3>
                                Vacatuner
                            </h3>
                            <p>
                                Brand, UX/UI, Development
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>
