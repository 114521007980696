<template>      
    <nav id="header-menu" class="navbar navbar-expand-lg text-white">
        <div class="container">     
            <div class="d-flex align-items-center">
                <a class="logo" href="/">
                    <img src="@/plugins/visivig/layout/assets/navbar-logo.svg" alt="wezeo-logo"/>   
                </a>
                <p class="slogan">
                    WE ARE YOUR
                    <br>
                    <span class="fw-bold">TECHNOLOGY PARTNER</span>
                </p>
            </div>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" 
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon text-white"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav align-items-center text-center">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#about-us">ABOUT US</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/case-study">OUR WORK</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#footer">GET IN TOUCH</a>
                    </li>
                </ul>
                <div class="d-flex align-items-center justify-content-center header-socials">
                    <a href="#">
                        <img src="@/plugins/visivig/layout/assets/facebook-icon.svg" alt="facebook">   
                    </a>
                    <a href="#">
                        <img src="@/plugins/visivig/layout/assets/ig-icon.svg" alt="instagram">
                    </a>  
                </div>
            </div>
        </div>
    </nav>
</template>

