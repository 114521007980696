<template>
    <section id="about-us">
        <div class="container">
            <div class="row align-items-lg-center">

                <div class="col-lg-6 header px-3 text-lg-start">
                    <h5 class="mb-2">
                        ABOUT US
                    </h5>
                    <h1>
                        #1 Hybrid app studio in Slovakia
                    </h1>
                    <p class="my-3">
                        We are digital enthusiasts with passion for web and mobile apps.
                    </p>
                </div>

                <div class="col-lg-6 data text-center text-sm-start d-sm-flex justify-content-center mt-sm-2">
                    <div class="text-lg-start mx-auto mx-sm-0">
                        <h1>
                            2013
                        </h1>
                        <p class="mx-auto mx-sm-0 mb-2 mb-sm-0">
                            SINCE
                        </p>
                    </div>
                    <div class="mx-auto mx-sm-0 ms-sm-4">
                        <h1>
                            244
                        </h1>
                        <p class="mb-2 mb-sm-0">
                            CLIENTS
                        </p>
                    </div>
                    <div class="mx-auto mx-sm-0 ms-sm-4">
                        <h1>
                            27
                        </h1>
                        <p>
                            TEAM MEMBERS
                        </p>
                    </div>
                </div>

            </div>

            <div class="partners px-2">
                <div class="d-flex align-items-center flex-wrap justify-content-center justify-content-md-between">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-finax.svg" alt="finax-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-vacatuner.svg" alt="vacatuner-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-retail.svg" alt="retail-solutions-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-cloudtalk.svg" alt="cloudtalk-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-whit.svg" alt="whit-logo">
                </div>     
                <div class="d-flex align-items-center flex-wrap justify-content-center justify-content-md-between">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-axfood.svg" alt="axfood-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-bergendahls.svg" alt="bergendahls-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-volg.svg" alt="volg-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-spar.svg" alt="spar-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-zmudri.svg" alt="zmudri-logo">
                    <img class="col-md-2" src="@/plugins/visivig/reusables/reusables-home/about-us/assets/about-us-fumbi.svg" alt="fumbi-logo">    
                </div>
    
            </div>
        </div>
    </section>
</template>

<script>
</script>
