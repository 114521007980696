<template>
    <section id="awards">
        <div class="container">
            <div class="d-flex flex-wrap align-items-center pt-md-3">

                <div class="col-6 col-md-4 col-lg px-3">
                    <a href="#" class="">
                        <div class="img-wrapper">
                            <img src="@/plugins/visivig/reusables/reusables-home/awards/assets/ces-awards.svg" alt="">
                        </div>
                        <p class="award-text">
                            Best Fintech Startup
                        </p>
                    </a>
                </div>

                <div class="col-6 col-md-4 col-lg px-3">
                    <a href="#">
                        <div class="img-wrapper">
                            <img src="@/plugins/visivig/reusables/reusables-home/awards/assets/awwwards.svg" alt="">
                        </div>
                        <p class="award-text">
                            5+ Design Awards                        
                        </p>
                    </a>
                </div>

                <div class="col-6 col-md-4 col-lg px-3">
                    <a href="#">
                        <div class="img-wrapper">
                            <img src="@/plugins/visivig/reusables/reusables-home/awards/assets/clutch-award.svg" alt="">
                        </div>
                        <div class="text-center">
                            <img src="@/plugins/visivig/reusables/reusables-home/awards/assets/clutch-award-text.svg" alt="">
                        </div> 
                    </a>
                </div>

                <div class="col-6 col-lg d-md-flex px-3">
                    <a class="ms-auto" href="#">
                        <div class="img-wrapper">
                            <img src="@/plugins/visivig/reusables/reusables-home/awards/assets/rulezz.svg" alt="">
                        </div>
                        <p class="award-text">
                            3+ Best Apps Developer Awards
                        </p>
                    </a>
                </div>

                <div class="col-12 col-md-6 col-lg d-md-flex px-3">
                    <a class="me-auto" href="#">
                        <div class="img-wrapper">
                            <img class="agency" src="@/plugins/visivig/reusables/reusables-home/awards/assets/accredited-agency-blue.webp" alt="">
                        </div>
                        <p class="award-text">
                            Top 30 Fintech
                        </p>
                    </a>
                </div>
            </div>
        </div>
        <div class="container">
            <h1 class="award-slogan">
                WE’VE BUILT SOLUTIONS
            </h1>
        </div>
    </section>
</template>

