<template>
    <div class="entry-content">
        <R-case-studies />
        <R-looking-for-hand />
    </div>
</template>

<script>
import RCaseStudies from '@/plugins/visivig/reusables/resuables-case-study/case-studies/r-case-studies.vue'
import RLookingForHand from '@/plugins/visivig/reusables/resuables-universal/looking-for-hand/r-looking-for-hand.vue'

export default {
    components: {
        RCaseStudies,
        RLookingForHand 
    }
}
</script>
