<template>
    <div class="entry-content">
        <R-fumbi-intro />
        <R-fumbi-about />
        <R-fumbi-done />
        <R-fumbi-challenge />
        <R-looking-for-hand />
        <R-fumbi-next-case-study />
    </div>
</template>

<script>
import RFumbiIntro from '@/plugins/visivig/reusables/reusables-fumbi/fumbi-intro/r-fumbi-intro.vue'
import RFumbiAbout from '@/plugins/visivig/reusables/reusables-fumbi/fumbi-about/r-fumbi-about.vue'
import RFumbiDone from '@/plugins/visivig/reusables/reusables-fumbi/fumbi-done/r-fumbi-done.vue'
import RFumbiChallenge from '@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/fumbi-challenge.vue'
import RLookingForHand from '@/plugins/visivig/reusables/resuables-universal/looking-for-hand/r-looking-for-hand.vue'
import RFumbiNextCaseStudy from '@/plugins/visivig/reusables/reusables-fumbi/fumbi-next-case-study/fumbi-next-case-study.vue'

export default {
    components: {
        RFumbiIntro,
        RFumbiAbout,
        RFumbiDone,
        RFumbiChallenge,
        RLookingForHand,
        RFumbiNextCaseStudy
    } 
}
</script>
