<template>
    <section id="team">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 header px-3 text-center">
                    <h5 class="mb-2">
                        TEAM WEZEO
                    </h5>
                    <h1>
                        Our experts                    
                    </h1>
                    <p class="my-3">
                        Always ready to bring your vision to life.                    
                    </p>
                </div>
            </div>

            <div class="members">
                <div class="row">

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/grishi.png">
                            <h5>
                                CEO
                            </h5>
                            <h4>
                                Grigor Ayrumyan
                            </h4>
                        </div>
                    </div>

                    <div class="member col-md-6 col-lg-3">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/mate.png">
                            <h5>
                                Co-Founder
                            </h5>
                            <h4>
                                Martin Klempa
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/glasnak.webp">
                            <h5>
                                CTO
                            </h5>
                            <h4>
                                Matej Glasnák
                            </h4>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/zuzka.png">
                            <h5>
                                Recruitment & Onboarding Administration
                            </h5>
                            <h4>
                                Zuzana Lukačovičová
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 member col-lg-3">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/matija.png">
                            <h5>
                                Lead Project Manager 
                            </h5>
                            <h4>
                                Matija Kadanec
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/marko.png">
                            <h5>
                                Business Development Manager 
                            </h5>
                            <h4>
                                Marko Hrivnák                            
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/riso.png">
                            <h5>
                                Graphic Designer                            
                            </h5>
                            <h4>
                                Richard Egyed                           
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/jaro_new.jpg">
                            <h5>
                                Senior Backend Developer
                            </h5>
                            <h4>
                                Jaroslav Macher                          
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/guspan.webp">
                            <h5>
                                Senior Backend Developer
                            </h5>
                            <h4>
                                Marek Guspan                            
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/blahos.png">
                            <h5>
                                Senior Frontend Developer
                            </h5>
                            <h4>
                                Marek Bláha                            
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/janci.png">
                            <h5>
                                Senior Frontend Developer
                            </h5>
                            <h4>
                                Ján Majerník                          
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/jakub.png">
                            <h5>
                                Frontend Developer
                            </h5>
                            <h4>
                                Jakub Jelínek                           
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/lukasNemec.jpg">
                            <h5>
                                Frontend Developer
                            </h5>
                            <h4>
                                Lukáš Nemec                           
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/letovanec.webp">
                            <h5>
                                Frontend Developer
                            </h5>
                            <h4>
                                Mário Letovanec                           
                            </h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 member">
                        <div class="inner">
                            <a href="#">
                                <img src="@/plugins/visivig/reusables/reusables-home/team/assets/linkedin-icon.svg" alt="linked-in">
                            </a>
                            <img class="avatar" src="@/plugins/visivig/reusables/reusables-home/team/assets/sebo.png">
                            <h5>
                                Backend Developer
                            </h5>
                            <h4>
                                Sebastian Halás                          
                            </h4>
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
    </section>
</template>


