<template>
    <section id="fumbi-about" class="py-5">
        <div class="container">

            <div class="title">
                <p>
                    01
                </p>
                <h2 class="mb-4 mb-md-5 fw-bold h1">
                    This is <span>Fumbi.network</span>
                </h2>
            </div>

            <div class="row">

                <div class="col-md-6 col-lg-4 mb-3 item">
                    <img class="me-4" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-about/assets/fumbi-beginning-img1.svg" alt="icon">
                    <div class="content">
                        <h3 class="mb-4">
                            Product page
                        </h3>
                        <p>
                            Main point of contact with customers. Provides introduction to the company and products.
                        </p>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4 item mb-3">
                    <img class="me-4" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-about/assets/fumbi-beginning-img2.svg" alt="icon">
                    <div class="content">
                        <h3 class="mb-4">
                            Academy
                        </h3>
                        <p>
                            Contains video courses aimed to educate users in the cryptocurrency industry.
                        </p>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4 item">
                    <img class="me-4" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-about/assets/fumbi-beginning-img3.svg" alt="icon">
                    <div class="content">
                        <h3 class="mb-4">
                            Client section
                        </h3>
                        <p>
                            Registered users can access their account and view their investment performance.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

