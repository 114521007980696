<template>
    <section id="looking-for-hand">
        <div class="container">
            <div class="row">

                <div class="header px-3 col-lg-6 text-center col-lg-12">
                    <h5 class="mb-2">
                        Looking for a hand ?
                    </h5>
                    <h1>
                        We offer you both of ours.                   
                    </h1>
                    <a class="visit" href="#">
                        <button class="btn">
                            WORK WITH US
                        </button>
                    </a>
                </div>

            </div>
        </div>
    </section>
</template>
