<template>
    <section id="fumbi-intro">
        <div class="container">
            <img class="mb-5" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-intro/assets/fumbi-main-visual-logo.svg" alt="fumbi">
            <h1 class="mb-5">
                Simple, transparent and safe investing in cryptocurrencies
            </h1>
            <p>
                Fumbi brings the opportunity to harness the potential of the fast-growing cryptocurrency industry to absolutely everyone.
            </p>
            <img class="intro-img" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-intro/assets/fumbi-main-visual-img.webp" alt="fumbi">
        </div>
    </section>
</template>
