<template>
    <section id="fumbi-challenge">
        <div class="container">

            <div class="row">
                <div class="title pb-5">
                    <p>
                        03
                    </p>
                    <h2>
                        Challenges
                    </h2>
                    <p>
                        Our main challenge was to create a UI that would clearly lay out the benefits of investing in crypto while explaining fees and threats and in a transparent manner.                    
                    </p>
                </div>
            </div>

            <div class="content row mt-8">
                <div class="col-md-8 mb-4 mb-md-0">
                    <div class="img-1 text-center">
                        <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img1.webp" alt="fumbi">
                    </div>

                    <div class="row">
                        <div class="col-8 img-2">
                            <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img2.svg" alt="fumbi">
                        </div>
                        <div class="col-4 img-3 align-self-center">
                            <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img3.svg" alt="fumbi">
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4">
                    <div class="img-4 d-flex py-5 h-100">
                        <img class="ms-auto my-3" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img4.svg" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 text">
                    <p class="text-center mx-auto my-5">
                        Education section of the portal posed another set of challenges of delivering a complex topic of cryptocurrencies so that even inexperienced users could understand the basics.
                    </p>
                </div>

                <div class="col-12">
                    <div class="img-5 text-center">
                        <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img5.svg" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 text">
                    <p class="text-center mx-auto my-5">
                        As a fast growing investment startup, Fumbi was under a lot of time pressure. They asked us to create a mobile app that would provide good value for money in the shortest possible time.
                    </p>
                </div>

                <div class="col-12 col-lg-4 mb-4 mb-lg-0 pe-lg-0">
                    <div class="img-6 text-center text-lg-end ps-lg-4 ps-xl-5">
                        <img class="pt-5 mt-lg-5" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img6.svg" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 col-lg-4 px-lg-0">
                    <div class="img-7 text-center h-100">
                        <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img7.svg" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 col-lg-4 ps-lg-0">
                    <div class="img-8 text-center text-lg-start pe-lg-4 pe-xl-5">
                        <img class="pt-5 mt-lg-5" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-challenge/assets/fumbi-challanges-img8.svg" alt="fumbi">
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

