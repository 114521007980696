<template>      
    <footer id="footer">
        <div class="container">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-4 order-last order-lg-0 mt-4 mt-lg-0">
                    <h3 class="logo">
                        <img src="@/plugins/visivig/layout/assets/logo-black.svg" alt="wezeo-logo">
                    </h3>
                    <p class="mt-3 mb-2 col-9">
                        We can take your idea to a market-ready MVP in just a few weeks!
                    </p>
                    <p class="mt-3">
                        © WEZEO 2022. All rights reserved.
                    </p>
                </div>

                <div class="col-12 col-md-6 col-lg-4 mb-2">
                    <div class="navigation d-flex flex-column flex-sm-row align-items-start align-items-md-center justify-content-between mb-lg-4">
                        <a href="#about-us" class="mb-1">
                            ABOUT US
                        </a>
                        <a href="/case-study" class="mb-1">
                            OUR WORK
                        </a>
                        <a href="#footer" class="mb-1">
                            GET IN TOUCH
                        </a>  
                    </div>

                    <div class="contact d-none d-md-flex mt-5 mt-md-4 order-2">
                        <div class="col-5 col-md-6">
                            <h5>
                                OFFICE
                            </h5>
                            <p>
                                Viedenská cesta 257,
                                <br>
                                851 06 Bratislava
                            </p>
                        </div>
                        <div class="col-7 col-md-6 ms-2 email">
                            <h5>
                                CONTACT
                            </h5>
                            <a href="mailto:info@wezeo.com">
                                info@wezeo.com
                            </a>
                        </div>      
                    </div>
                </div>
                
                <div class="contact d-flex d-md-none mt-5 mt-md-3 order-2">
                    <div class="col-5 col-md-6">
                        <h5>
                            OFFICE
                        </h5>
                        <p>
                            Viedenská cesta 257,
                            <br>
                            851 06 Bratislava
                        </p>
                    </div>
                    <div class="col-7 col-md-6 ms-2 email">
                        <h5>
                            CONTACT
                        </h5>
                        <a href="mailto:info@wezeo.com">
                            info@wezeo.com
                        </a>
                    </div>      
                </div>
                
                <div class="col-12 col-md-6 col-lg-4 socials order-1 d-flex justify-content-start justify-content-md-end align-items-md-start mt-4 mt-md-0 ">
                    <div class="partners d-flex d-md-block flex-column flex-sm-row justify-content-sm-between align-items-sm-end flex-md-wrap w-100">
                        <h4>
                            WE ARE YOUR 
                            <br>
                            <span class="lh-base fw-bold">TECHNOLOGY PARTNER</span> 
                        </h4>
                        <div class="d-flex mt-3 mt-md-4">
                            <div class="social-item">
                                <img src="@/plugins/visivig/layout/assets/facebook-black.svg" alt="facebook">
                                <img class="d-none" src="@/plugins/visivig/layout/assets/facebook-white.svg" alt="facebook">
                            </div>
                            <div class="social-item">
                                <img src="@/plugins/visivig/layout/assets/instagram-black.svg" alt="instagram">
                                <img class="d-none" src="@/plugins/visivig/layout/assets/instagram-white.svg" alt="instagram">
                            </div>
                            <div class="social-item">
                                <img src="@/plugins/visivig/layout/assets/linkedin-black.svg" alt="linked-in">
                                <img class="d-none" src="@/plugins/visivig/layout/assets/linkedin-white.svg" alt="linked-in">
                            </div>
                            <div class="social-item">
                                <img src="@/plugins/visivig/layout/assets/youtube-black.svg" alt="youtube">
                                <img class="d-none" src="@/plugins/visivig/layout/assets/youtube-white.svg" alt="youtube">
                            </div>
                        </div>    
                    </div> 

                </div>

            </div>
        </div>
    </footer>
</template>
