<template>
    <section id="fumbi-done">
        <div class="container">
            <div class="row">

                <div class="title py-5">
                    <p>
                        02
                    </p>
                    <h2>
                        What Was Done
                    </h2>
                    <p>
                        Our first job for Fumbi was to create a new graphic identity for their presentation website. Moving our cooperation further, the client asked us to redesign the UX and UI of their customer portal. Another part of our assignment was to implement an education section to the portal and develop a mobile app for customers of Fumbi.
                    </p>
                </div>

                <div class="col-12 img-1">
                    <div>
                        <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-done/assets/fumbi-what-img1.webp" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 col-md-6 img-2">
                    <div>
                        <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-done/assets/fumbi-what-img2.webp" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 col-md-6 img-3">
                    <div>
                        <img src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-done/assets/fumbi-what-img3.webp" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 text">
                    <p class="text-center mx-auto my-5">
                        Thanks to different metrics of customer behavior we have been able to continuously recommend new solutions designed to improve user interaction keeping the portal easy to use and navigate.
                    </p>
                </div>

                <div class="col-12 col-md-6 img-4 pe-md-0">
                    <div class="text-center text-md-end ps-md-0">
                        <img class="pb-md-0" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-done/assets/fumbi-what-img4.svg" alt="fumbi">
                    </div>
                </div>

                <div class="col-12 col-md-6 img-5 ps-md-0">
                    <div class="text-center text-md-start h-100 pe-md-0">
                        <img class="pt-md-0" src="@/plugins/visivig/reusables/reusables-fumbi/fumbi-done/assets/fumbi-what-img5.svg" alt="fumbi">
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
